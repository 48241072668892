import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <iframe data-hj-allow-iframe="" allowTransparency="false" title="Fluid Canvas" frameBorder="0" src="../../../../fluid-frame.html"></iframe>
  </Layout>
)

export default IndexPage
